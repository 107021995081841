import React, { useEffect, useRef, useState } from "react";
import {
  LayersControl,
  TileLayer,
  MapContainer,
  GeoJSON,
  LayerGroup,
  ScaleControl,
  Tooltip,
  Marker,
  Polyline,
  Pane,
  CircleMarker,
  Popup,
} from "react-leaflet";
import { styled } from "@mui/material/styles";
import { getMapConfig } from "./MapConfig";
import "leaflet/dist/leaflet.css";
import "leaflet-ruler/src/leaflet-ruler.css";
import AuthorizedTileLayer from "./AuthorizedTileLayer";
import LocationMarker from "./LocationMarker";
import "leaflet-rotate";
import { WalkTrail, PathHistory } from "./WalkTrail";
import {
  GPS,
  FixMode,
} from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/gps";
import {
  FixModeToColor,
  BaseZIndex,
  defaultMapCenter,
  defaultMapZoom,
  GPSWithSystemState,
} from "../config";
import Control from "react-leaflet-custom-control";
import { Button } from "@mui/material";
import { CenterFocusStrong } from "@mui/icons-material";
import L from "leaflet";
import { TransmitterStatus } from "@exodigo/exodigo-transmitter-protocols/dist/command";
import { TransmitterMarkers } from "./TransmitterMarker";
import { SystemState } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/system";
import styles from "./Map.module.css";
import ScalableText from "./ScalableText";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "clear.svg",
  iconUrl: "clear.svg",
  shadowUrl: "",
});

const monumentIcon = L.icon({ iconUrl: "monument.svg", iconSize: [50, 50] });

const CustomMapContainer = React.forwardRef((props: any, ref) => {
  return <MapContainer {...props} ref={ref} />;
});

const StyledMapContainer = styled(CustomMapContainer)(() => ({
  width: "100vw",
  height: "100vh",
}));

interface GeoCoordinate {
  lat: number;
  lng: number;
}

function calculateCentroid(coordinates: [number, number][]): GeoCoordinate {
  if (coordinates.length === 0) {
    throw new Error("No coordinates provided");
  }

  const total = coordinates.reduce(
    (acc, [lat, lng]) => {
      acc.lat += lat;
      acc.lng += lng;
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  const centroid = {
    lat: total.lng / coordinates.length,
    lng: total.lat / coordinates.length,
  };

  return centroid;
}

const isScanningOptions: (SystemState | undefined)[] = [
  SystemState.SYSTEM_SCANNING,
  SystemState.SYSTEM_SCAN_PAUSED,
];

export default function Map(props: MapProps) {
  const [travelCourseHistory, setTravelCourseHistory] = useState<
    GPSWithSystemState[]
  >([]);
  const [travelCourse, setTravelCourse] = useState<any>(null);
  const [resetScan, setResetScan] = useState(true);
  const [shouldCenter, setShouldCenter] = useState(true);

  useEffect(() => {
    let updated_travel_course_history = travelCourseHistory;
    if (isScanningOptions.includes(props.systemState)) {
      if (resetScan) {
        setTravelCourse({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [[props.gpsMessage.lon, props.gpsMessage.lat]],
          },
        });
        setTravelCourseHistory([]);
        updated_travel_course_history = [];
        setResetScan(false);
      } else if (props.systemState === SystemState.SYSTEM_SCANNING) {
        let updated_travel_course = travelCourse;
        updated_travel_course.geometry.coordinates.push([
          props.gpsMessage.lon,
          props.gpsMessage.lat,
        ]);
        setTravelCourse(updated_travel_course);
      }
    } else {
      setResetScan(true);
    }
    if (
      props.gpsMessage !== undefined &&
      props.gpsMessage !== null &&
      props.gpsMessage.utcTimestamp !== 0
    ) {
      updated_travel_course_history.push([props.gpsMessage, props.systemState]);
      setTravelCourseHistory(updated_travel_course_history);
    }
  }, [
    props.systemState,
    props.gpsMessage,
    travelCourse,
    resetScan,
    travelCourseHistory,
  ]);

  const mapConfig = getMapConfig();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="mapContainer" ref={mapContainerRef}>
      <StyledMapContainer
        maxZoom={mapConfig.maxZoom}
        center={defaultMapCenter}
        zoom={defaultMapZoom}
        rotate={true}
        rotateControl={{ closeOnZeroBearing: false }}
        touchRotate={true}
        zoomSnap={0}
      >
        {mapConfig.roadmapTilesLayers.map((layer, i) => (
          <TileLayer
            key={`TileLayer${i}`}
            url={layer.urlFormat}
            attribution={layer.attributions}
            minNativeZoom={layer.minNativeZoom}
            maxNativeZoom={layer.maxNativeZoom}
            maxZoom={mapConfig.maxZoom}
          />
        ))}
        <ScaleControl />
        <Control position="bottomleft" prepend={true}>
          <div style={{ backgroundColor: "transparent" }}>
            {Object.entries(FixModeToColor).map(([fixMode, color]) => (
              <div
                key={fixMode}
                style={{
                  backgroundColor: color + "80",
                  padding: 3,
                  borderRadius: 2,
                }}
              >
                {" "}
                {FixMode[fixMode as any].replace("FIX_MODE_", "")}
              </div>
            ))}
          </div>
        </Control>
        <LayersControl position="topright">
          <Pane name="Satellite Tiles">
            <LayersControl.Overlay checked={true} name="Satellite Tiles">
              <LayerGroup>
                {mapConfig.satelliteTilesLayer.map((layer, i) => (
                  <AuthorizedTileLayer
                    key={`TileLayer${i}`}
                    url={layer.urlFormat}
                    attribution={layer.attributions}
                    minNativeZoom={layer.minNativeZoom}
                    maxNativeZoom={layer.maxNativeZoom}
                    maxZoom={mapConfig.maxZoom}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 6 }} name="GPS History">
            <LayersControl.Overlay checked={true} name="GPS History">
              <LayerGroup>
                <PathHistory
                  lat={props.gpsMessage.lat}
                  travelCourse={travelCourseHistory}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane
            style={{ zIndex: BaseZIndex + 9 }}
            name="Transmitters Locations"
          >
            <LayersControl.Overlay checked={true} name="Transmitters Locations">
              <LayerGroup>
                {
                  <TransmitterMarkers
                    transmittersLocations={props.transmittersLocations}
                  />
                }
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 2 }} name="Planned Polygons">
            <LayersControl.Overlay checked={true} name="Planned Polygons">
              <LayerGroup>
                {props.polygons.map((p) => {
                  const parsedData = JSON.parse(p.geoData);
                  const coords = parsedData.geometry.coordinates[0];

                  const center = calculateCentroid(coords);
                  console.log("center", center);

                  return (
                    <GeoJSON key={p.id} data={parsedData} pathOptions={{ color: p.color }}>
                      <Pane
                        style={{ zIndex: BaseZIndex + 3 }}
                        name={`Planned Polygons Popup ${p.name}`}
                      >
                        <Popup>
                          <table
                            className={styles.table}
                            style={{ fontSize: "18px" }}
                          >
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{p.name}</td>
                              </tr>
                              <tr>
                                <td>Comment</td>
                                <td>{p.comment}</td>
                              </tr>
                              <tr>
                                <td>Type</td>
                                <td>{p.type}</td>
                              </tr>
                              <tr>
                                <td>Sensor types</td>
                                <td>{p.sensorTypes.join(", ")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Popup>
                        {
                          <Marker
                            position={center}
                            icon={L.divIcon({html: `<div style="font-size: 25px;">${p.name}<div/>`, className: 'text-class-name'})}
                          />
                        }
                      </Pane>
                    </GeoJSON>
                  );
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 8 }} name="ExoSpray">
            <LayersControl.Overlay checked={true} name="ExoSpray">
              <LayerGroup>
                {props.pipes.map((p) => (
                  <GeoJSON
                    data={JSON.parse(p)}
                    pathOptions={{ color: "red" }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane
            style={{ zIndex: BaseZIndex + 2 }}
            name="Requested Transmitters Locations"
          >
            <LayersControl.Overlay
              checked={false}
              name="Requested Transmitters Locations"
            >
              <LayerGroup>
                {props.requestdTransmittersLocations.map((p) => {
                  let coordinates = JSON.parse(p.geoData)["coordinates"];
                  return (
                    <CircleMarker
                      center={[coordinates[1], coordinates[0]]}
                      pathOptions={{ color: p.color }}
                      radius={10}
                    />
                  );
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 8 }} name="Monuments">
            <LayersControl.Overlay checked={false} name="Monuments">
              <LayerGroup>
                {props.Monuments.map((p) => (
                  <Marker
                    key={p.id_}
                    position={[p.lat, p.lon]}
                    icon={monumentIcon}
                  >
                    <Tooltip>{`Monument ID: ${p.id_}`}</Tooltip>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 2 }} name="Previous Scans">
            <LayersControl.Overlay checked={true} name="Previous Scans">
              <LayerGroup>
                {props.previousTravelCourses.map((travel_course) => (
                  <Polyline
                    positions={travel_course}
                    pathOptions={{ weight: props.trailWidth }}
                  ></Polyline>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
          <Pane style={{ zIndex: BaseZIndex + 2 }} name="Requested Re-Scans">
            <LayersControl.Overlay checked={false} name="Requested Re-Scans">
              <LayerGroup>
                {props.reScans.map((p) => (
                  <GeoJSON data={JSON.parse(p)} />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </Pane>
        </LayersControl>
        <Control position="topright">
          <Button
            variant="contained"
            color={shouldCenter ? "primary" : "inherit"}
            onClick={() => {
              setShouldCenter((val) => !val);
            }}
          >
            <CenterFocusStrong />
          </Button>
        </Control>
        {props.gpsMessage !== undefined && props.gpsMessage !== null && (
          <Pane style={{ zIndex: BaseZIndex + 10 }} name="LocationMarker">
            <LocationMarker
              gpsMessage={props.gpsMessage}
              center={shouldCenter}
              setShouldCenter={setShouldCenter}
            />
          </Pane>
        )}
        {props.gpsMessage !== undefined && props.gpsMessage !== null && (
          <Pane style={{ zIndex: BaseZIndex + 4 }} name="WalkTrail">
            <WalkTrail
              lat={props.gpsMessage.lat}
              travelCourse={travelCourse}
              trailWidth={props.trailWidth}
            />
          </Pane>
        )}
      </StyledMapContainer>
    </div>
  );
}

export interface MapProps {
  systemState: SystemState | undefined;
  gpsMessage: GPS;
  polygons: any[];
  pipes: string[];
  transmittersLocations: TransmitterStatus[];
  trailWidth: number;
  reScans: string[];
  requestdTransmittersLocations: Transmitter[];
  Monuments: Monument[];
  previousTravelCourses: [number, number][][];
}

export interface Monument {
  id_: string;
  lat: number;
  lon: number;
}

export interface Transmitter {
  geoData: string;
  color: string;
}
