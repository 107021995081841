import "./App.css";
import {MainDisplay} from "./MainDisplay/MainDisplay";

function App() {
  return (
    <MainDisplay />
  );
}

export default App;
