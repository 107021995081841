import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalInstance } from "./Authentication";

let useMsal = false

const container = document.getElementById("root");
const root = createRoot(container!);
if (useMsal) {
  root.render(
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
else {
  root.render(<App />);
}
