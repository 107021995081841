import { useEffect, useState } from "react";
import Map, { Monument, Transmitter } from "../Map/Map";
import { client, MessagesTopics } from "../WebSocketClient/WebSocketClient";
import { GPS } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/gps"
import { SystemRegistry } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/system"
import { UIMessage, UICommand } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/ui"
import { TransmitterStatus } from "@exodigo/exodigo-transmitter-protocols/dist/command"
import { Reader } from "protobufjs";
import { authGet, setAuthToken } from "../Authentication";
import { exoplannerAddress, exoservicesAddress, MonumentsMaxDistance } from "../config";
import React from "react";
import { Buffer } from 'buffer';

let siteName = ""
let systemRegistryBuffer = Buffer.from("")

export const MainDisplay = () => {
  const [gpsMessage, setGPSMessage] = useState<GPS>(GPS.create());
  const [systemRegistry, setSystemRegistry] = useState<SystemRegistry | null>(null);
  const [polygons, setPolygons] = useState<string[]>([]);
  const [pipes, setPipes] = useState<string[]>([]);
  const [reScans, setReScans] = useState<string[]>([]);
  const [requestdTransmittersLocations, setRequestdTransmittersLocations] = useState<Transmitter[]>([]);
  const [monuments, setMonuments] = useState<Monument[]>([]);
  const [trailWidth, setTrailWidth] = useState<number>(0.5);
  const [transmittersLocations] = useState<{ [key: number]: TransmitterStatus }>({});
  const [previousTravelCourses, setPreviousTravelCourses] = useState<[number, number][][]>([]);
  const lastUpdateTime = React.useRef({ value: Date.now() })

  useEffect(() => {
    client.on("message",
      function (topic: string, message: Buffer) {
        if (topic === MessagesTopics.GPS) {
          setGPSMessage(GPS.decode(Reader.create(message)))
        } else if (topic === MessagesTopics.SystemRegistry) {
          if (!systemRegistryBuffer.equals(message)) {
            systemRegistryBuffer = message
            setSystemRegistry(SystemRegistry.decode(Reader.create(message)))
          }
        } else if (topic === MessagesTopics.UiControl) {
          const uiMessage = UIMessage.decode(Reader.create(message))
          console.log("got 'ui_control' message:", uiMessage)
          if (uiMessage?.command === UICommand.UI_SET_TRAIL_WIDTH) { setTrailWidth(uiMessage.additionalArgs) }
          else if (uiMessage?.command === UICommand.UI_SET_AUTH_TOKEN) { setAuthToken(uiMessage.additionalArgs) }
        } else if (topic === MessagesTopics.TransmittersData) {
          const transmitterMessage = TransmitterStatus.decode(Reader.create(message))
          transmittersLocations[transmitterMessage.mac] = transmitterMessage
        }
      }
    )
  }, [transmittersLocations])

  useEffect(() => {
    async function getData() {
      let scanInfo = systemRegistry?.scanInfo
      let newSiteName = scanInfo?.site
      if (newSiteName !== undefined && newSiteName !== "" && newSiteName !== siteName) {
        siteName = newSiteName
        const site_data = (await authGet(`${exoplannerAddress}/analysis/sitePlans?name=${newSiteName}`))?.data
        const received_pipes = (await authGet(`${exoplannerAddress}/analysis/layers/exodaq/geojsons?siteName=${newSiteName}&layerName=ExoSpray`))?.data.geoData
        if (scanInfo !== undefined && scanInfo.sensors.length !== 0) {
          const received_previous_travel_courses = (await authGet(
            `${exoservicesAddress}/travel_course/view/find_by_scan_parts?site=${newSiteName}&sensor=${scanInfo.sensors}&p=${scanInfo.polygon}&s=${scanInfo.snake}`
          ))?.data.travel_courses
          console.log("previous_travel_courses: ", received_previous_travel_courses)
          if (received_previous_travel_courses !== undefined) { setPreviousTravelCourses(received_previous_travel_courses) }
        }
        console.log("site: ", newSiteName)
        console.log("site_data: ", site_data)
        console.log("pipes: ", received_pipes)
        if (site_data !== undefined) {
          setPolygons(site_data.plannedPolygons)
          const received_requested_transmitters_locations = site_data.plannedPolygons.map((p: any) => p.plannedTransmitters.map((t: any) => ({"geoData": t.geoData, "color": t.color}))).flat()
          setRequestdTransmittersLocations(received_requested_transmitters_locations)
          if (site_data.rescansPolygons !== undefined) {
            setReScans(site_data.rescansPolygons)
          }
        }
        if (received_pipes !== undefined) { setPipes(received_pipes) }
      }
    }
    getData()
  }, [systemRegistry]
  );
  useEffect(() => {
    async function updateMonuments() {
      if ((Date.now() - lastUpdateTime.current.value) > 10000) {
        lastUpdateTime.current.value = Date.now()
        const received_monuments = (await authGet(`${exoservicesAddress}/monuments/view/find_by_lat_lon?lat=${gpsMessage.lat}&lon=${gpsMessage.lon}&max_distance=${MonumentsMaxDistance}`))?.data.monuments
        if (received_monuments !== undefined && monuments !== received_monuments) { setMonuments(received_monuments) }
        else { setMonuments([]) }
      }
    }
    updateMonuments()
  }, [gpsMessage, monuments]
  );

  return <Map
    systemState={systemRegistry?.state}
    gpsMessage={gpsMessage}
    polygons={polygons}
    trailWidth={trailWidth}
    pipes={pipes}
    transmittersLocations={Object.values(transmittersLocations)}
    reScans={reScans}
    requestdTransmittersLocations={requestdTransmittersLocations}
    Monuments={monuments}
    previousTravelCourses={previousTravelCourses}
  />
}
