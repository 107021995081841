import { Marker, Popup } from "react-leaflet"
import { TransmitterSetFrequency, TransmitterStatus, transmitterStateToJSON } from "@exodigo/exodigo-transmitter-protocols/dist/command";
import L from "leaflet";
import { useEffect, useState } from "react";
import { sendMessage } from "../WebSocketClient/WebSocketClient";

async function loadIcon(): Promise<string> {
    return (await fetch('tx_box_red.svg')).text();
}

async function BatteryToIcon(level: number) {
    let color = "#000000"
    if (level <= 8) { color = "#FF0000" }
    else if (level <= 10) { color = "#FF8080" }
    else if (level <= 12) { color = "#FFFF00" }
    else if (level <= 100) { color = "#00FF00" }
    return L.icon({
        iconUrl: 'data:image/svg+xml,' + encodeURIComponent((await loadIcon()).split("fill:#ff0000").join(`fill:${color}`)),
        iconSize: [50, 95],
    });

}

export function TransmitterMarkers(props: TransmitterMarkersProps) {
    const [icons, setIcons] = useState<L.Icon[]>([]);

    useEffect(() => {
        async function func() {
            let cur_icons: L.Icon[] = []
            for (const t of props.transmittersLocations) { cur_icons.push(await BatteryToIcon(t.voltage)) }
            setIcons(cur_icons)
        }
        func()
    }, [props])
    return <>{
        props.transmittersLocations.map(
            (t, i) => icons[i] ? <Marker key={t.mac} position={[t.lat, t.lon]} icon={icons[i]}>
                <Popup>
                    {`mac=${t.mac.toString(16).toUpperCase().padStart(4, "0")}`}<br />
                    {`freq=${t.frequencies[t.activeFrequencyIndex]}`}<br />
                    {`Current=${t.rfCurrent.toFixed(2)}`}<br />
                    {`LoRa-SNR=${t.loraSnr.toFixed(3)}`}<br />
                    {`State=${transmitterStateToJSON(t.state).replace('TRANSMITTER_STATE_', '')}`}
                    <div className="freqencies">
                        {t.frequencies.map((freq, index) => [<input key={`${freq}_input`} type="radio" id={`${freq}`} className="freqencies" name="freqency" value={index} onChange={
                            () => sendMessage('transmitter_command', TransmitterSetFrequency.encode({mac: t.mac, freqIndex: index}).finish())
                        } checked={t.activeFrequencyIndex === index} />,
                            <label key={`${freq}_label`} htmlFor={`${freq}`} title="text">{freq}</label>])}
                    </div>
                </Popup>
            </Marker> : null
        )
    }</>
}

interface TransmitterMarkersProps {
    transmittersLocations: TransmitterStatus[],
}
