const maxZoom = 25;
const maxTilesZoom = 22;
const maxOpenStreetMapZoom = 19;

export function getMapConfig(): GetMapConfigResponse {
  return {
    maxZoom: maxZoom,
    roadmapTilesLayers: [
      {
        urlFormat: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attributions:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          maxNativeZoom: maxOpenStreetMapZoom,  // Maximum zoom supported
      },
    ],
    satelliteTilesLayer: [
      {
        urlFormat:
          `https://342k19gmi6.execute-api.il-central-1.amazonaws.com/prod/tiles_server_with_satellite_api_gateway_pipesqa-prod_resource?z={z}&x={x}&y={y}`,
          maxNativeZoom: maxTilesZoom,  // Maximal zoom we support tiles for
      },
    ]
  };
}

export interface TilesLayerConfig {
  urlFormat: string;
  attributions?: string;
  minNativeZoom?: number;
  maxNativeZoom?: number;
  minZoom?: number;
}

export interface GetMapConfigResponse {
  maxZoom: number;
  roadmapTilesLayers: TilesLayerConfig[];
  satelliteTilesLayer: TilesLayerConfig[];
}
