import { useEffect, useRef, useState } from "react";
import { Marker, useMap, useMapEvents } from "react-leaflet"
import L, { LatLngExpression } from "leaflet";
import { FixModeToColor, defaultMapZoom } from "../config";
import { GPS } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/gps";
import { FixMode } from "@exodigo/exodigo-transmitter-protocols/dist/command";

const initialIcon = L.icon({
  iconUrl: "gps_fixed_blue_red.svg",
  iconSize: [50, 50],
});

async function loadIcon(): Promise<string> {
  return (await fetch('gps_fixed_blue_red.svg')).text();
}

async function FixModeToIcon(mode: FixMode) {
  return L.icon({
    iconUrl: 'data:image/svg+xml,' + encodeURIComponent((await loadIcon()).split('fill="#1a1aeb"').join(`fill="${FixModeToColor[mode]}"`)),
    iconSize: [50, 50],
  });
}

export default function LocationMarker(props: LocationMarkerProps) {
  const [icon, setIcon] = useState<L.Icon>(initialIcon);
  const map = useMap();
  const markerRef = useRef<any>()

  useMapEvents({
    zoomend: () => { if (props.center) { map.panTo([props.gpsMessage.lat, props.gpsMessage.lon] as LatLngExpression) } },
    drag: () => { props.setShouldCenter(false) },
  })

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      if (props.center) {
        map.panTo([props.gpsMessage.lat, props.gpsMessage.lon] as LatLngExpression);
      }
      marker.setRotation((props.gpsMessage.heading) / 180 * Math.PI)
    }
  }, [map, props.gpsMessage, props.center]);

  useEffect(() => {
    async function updataIcon() {
      setIcon(await FixModeToIcon(props.gpsMessage.fixMode))
    }

    updataIcon()
  }, [props.gpsMessage.fixMode]
  )

  return <Marker
    ref={(ref) => { markerRef.current = ref }}
    position={[props.gpsMessage.lat, props.gpsMessage.lon] as LatLngExpression}
    icon={icon}
    eventHandlers={{
      click: (_e) => {
        map.setZoom(defaultMapZoom)
        props.setShouldCenter(true)
      },
    }}
  ></Marker>;
}

interface LocationMarkerProps {
  gpsMessage: GPS,
  center: boolean,
  setShouldCenter: (val: boolean) => void;
}
