import { GPS } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/gps";
import { SystemState } from "@exodigo/exodigo-exodaq-protocols/dist/exodigo/protobuf/exodaq_protocols/system";
import { FixMode } from "@exodigo/exodigo-transmitter-protocols/dist/command";

export type GPSWithSystemState = [GPS, SystemState | undefined];
export const exoplannerAddress = "https://rs7kgc1vud.execute-api.us-east-1.amazonaws.com/prod";
export const exoservicesAddress = "https://exoservices.apigateway.prod.internal.exodigo.ai/prod";
export const defaultMapZoom = 24.5;
export const defaultMapCenter = [32.06405, 34.7905];
export const MonumentsMaxDistance = 20000;
export const BaseZIndex = 550;
export const FixModeToColor = {
  [FixMode.FIX_MODE_FIXED]: "#00FF00",  // Lime
  [FixMode.FIX_MODE_FLOAT]: "#FFFF00",  // Yellow
  [FixMode.FIX_MODE_NO_FIX]: "#000000",  // Black
  [FixMode.FIX_MODE_DGNSS]: "#008080",  // Teal
  [FixMode.FIX_MODE_3D]: "#FF00FF",  // Magneta
  [FixMode.UNRECOGNIZED]: "#FF0000",  // Red
};
