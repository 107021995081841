import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import axios from "axios";

const msalConfig = {
  auth: {
    clientId: "b01dbe4a-31a7-4fc2-b8db-3eaa4cb1a60b", // This is the ONLY mandatory field that you need to supply.
    authority: "https://login.microsoftonline.com/da061ec7-6389-4987-be6b-51c45283828e/", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "http://localhost:8080/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["api://exoplanner.internal.exodigo.ai/application"],
};

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export async function get_token(): Promise<AuthenticationResult> {
  const account = msalInstance.getActiveAccount();

  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  return await msalInstance.acquireTokenSilent({...loginRequest, account: account});
}

export function setAuthToken(token: string){
  window.sessionStorage.setItem("AuthToken", token);
}
export async function authGet(url: string, config?: any) {
  let token = window.sessionStorage.getItem("AuthToken")

  if (token === null || token === undefined){
    const authResult = await get_token()
    setAuthToken(authResult.accessToken)
    token = authResult.accessToken
  }
  
  if (token === null || token === undefined){
    return null
  }

  if (!config) {
    config = {}
  }

  if (!config["headers"]) {
    config["headers"] = {}
  }

  if (!config["headers"]["Authorization"]) {
    config["headers"]["Authorization"] = `Bearer ${token}`
  }

  return safeAxiosGet(url, config);
}

export async function safeAxiosGet(url: string, config?: any) {
  if (!config) {config = {}}
  if (!config["timeout"]){config["timeout"] = 10000}

  try {
    return await axios.get(url, config);
  }
  catch (error) {
    console.log("axios.get failed with error: ", error);
  }
}
