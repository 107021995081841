import mqtt from "mqtt/dist/mqtt";
import {Buffer} from "buffer";

export const client = mqtt.connect("ws://localhost:1883");

export enum MessagesTopics {
    GPS = "gps",
    SystemRegistry = "system_registry",
    SystemControl = "system_control",
    UiControl = "ui_control",
    TransmittersData = "transmitters_data",
}

Object.values(MessagesTopics).map(topic => client.subscribe(topic))

export function sendMessage(topic: string, data: any){
    client.publish(topic, Buffer.from(data))
}
